import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { navigate } from 'gatsby';
import request from '~utils/request';
import { getAccessBlockingPopupId } from '~utils/authorization-helper';
import { getGuestTranscriptRedirectProps } from '~utils/required-subscription-plan';
import { VIDEO_READY, VIDEO_PLAY, VIDEO_WATCHED } from '~hooks/useApi';
import closeFullscreen from '~utils/close-fullscreen';
import AnalyticsTracking, { ANALYTIC_EVENT } from '~utils/analytics-tracking';
import { pushDataLayerEvent } from '~utils/data-layer';
import { POPUPS_MAP, POPUP_CONFIGS } from '~layout/Popups';

const MarketingHeroPlaylistLayout = loadable(() => import('~components/playlist'), {
  resolveComponent: components => components.MarketingHeroPlaylistLayout
});

const MarketingHeroPlaylist = props => {
  const { playlist, labels, giftToken, allowedSeconds, videoId } = props;

  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [popup, setPopup] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // set the initial video
  useEffect(() => {
    if (playlist?.videos?.length > 0 && videoId >= 0) {
      setCurrentVideo(playlist.videos[videoId]);
      setCurrentVideoIndex(videoId);
    }
  }, [videoId, playlist?.videos]);

  const showContentBlockingPopup = useCallback(
    popupId => {
      // if no popup id is provided, we set access blocking popup id as the default popup id
      let applicablePopup = popupId;
      if (!applicablePopup) {
        /*
        if no access blocking popup id is set from the authorize user,
        let's get default access blocking popup id based on the required subscription plan
      */
        applicablePopup = getAccessBlockingPopupId(playlist?.required_subscription_plan);
      }
      setIsPopupOpen(true);
      setPopup(applicablePopup);
      setIsPlaying(false);
      closeFullscreen();
    },
    [playlist?.required_subscription_plan]
  );

  const onReady = useCallback(() => {
    const { title, uid: playlistUid } = playlist;
    const { prismicId, uid: videoUid } = currentVideo;
    setIsPopupOpen(false);
    setPopup(null);
    setIsPlaying(true);
    request(VIDEO_READY({ videoId: prismicId }))
      .then(() => {
        pushDataLayerEvent('video_start', {
          playlistName: title,
          videoName: currentVideo.title
        });
        AnalyticsTracking.trackEvent(ANALYTIC_EVENT.VIDEO_START, {
          'Playlist UID': playlistUid,
          'Playlist Title': title,
          'Video UID': videoUid,
          'Video Title': currentVideo.title
        });
      })
      .catch(console.error);
  }, [currentVideo, playlist]);

  const onStart = useCallback(() => {
    const { prismicId: playlistId, uid: playlistUid } = playlist;
    const { prismicId: prismicVideoId } = currentVideo;

    setIsPlaying(true);

    request(VIDEO_PLAY({ videoId: prismicVideoId, playlistId, playlistUid, giftToken })).catch(
      console.error
    );
  }, [playlist, currentVideo, giftToken]);

  const navigateToVideo = useCallback(
    video => {
      navigate(`/video/${playlist?.uid}/${video?.uid}`);
    },
    [playlist?.uid]
  );

  const onProgress = useCallback(
    ({ playedSeconds }) => {
      const { prismicId: playlistId, uid: playlistUid, allowFullWatch } = playlist;
      const { prismicId: videoPrismicId, title, uid: videoUid } = currentVideo;

      if (!allowFullWatch && playedSeconds > allowedSeconds && isPlaying) {
        setIsPlaying(false);
        showContentBlockingPopup(POPUPS_MAP.IN_VIDEO_CONTENT_BLOCKER);
        AnalyticsTracking.trackEvent(ANALYTIC_EVENT.VIDEO_END, {
          'Playlist UID': playlistUid,
          'Playlist Title': title,
          'Video UID': videoUid,
          'Video Title': currentVideo.title
        });
        request(VIDEO_WATCHED({ playlistId, videoId: videoPrismicId })).catch(console.error);
      }
    },
    [playlist, allowedSeconds, currentVideo, showContentBlockingPopup, isPlaying]
  );

  const onEnded = useCallback(() => {
    const { title, prismicId: prismicVideoId } = currentVideo;

    const { videos, prismicId: playlistId, allowFullWatch } = playlist;
    const nextVideoIndex = currentVideoIndex + 1;
    const hasNextVideoInPlaylist = !!videos[nextVideoIndex];

    /**
     * When the marketing site is on, let's show the content blocking popup at the end of the last video
     * This would ensure that,
     *  - the free content also would get the blocker at the end of the last video
     *  - the non-free content which are less than 90 seconds also gets the blocker
     */
    if (allowFullWatch && hasNextVideoInPlaylist) {
      // play the next video in the playlist
      navigateToVideo(videos[nextVideoIndex]);
    } else {
      // last video or only video in the playlist
      showContentBlockingPopup(POPUPS_MAP.IN_VIDEO_CONTENT_BLOCKER);
    }
    // track analytics
    pushDataLayerEvent('video_watched', {
      playlistName: title,
      videoName: currentVideo.title
    });
    request(VIDEO_WATCHED({ playlistId, videoId: prismicVideoId })).catch(console.error);
  }, [playlist, currentVideo, currentVideoIndex, navigateToVideo, showContentBlockingPopup]);

  const handleBlockingPopupClose = useCallback(() => {
    // If the user is closing the popup which is a full blocker
    // we redirect them to the home page so they cant watch anymore
    if (POPUP_CONFIGS.redirectHomeOnClose.includes(popup)) {
      navigate('/');
    }
    const popupConfig = POPUP_CONFIGS[popup];
    if (popupConfig?.autoplayOnClose) {
      setIsPlaying(true);
    }
    setIsPopupOpen(false);
  }, [popup]);

  const showGiftPopup = useCallback(() => {
    const guestRedirectProps = getGuestTranscriptRedirectProps();
    const isPcContent = playlist?.required_subscription_plan === 4;

    if (isPcContent) {
      navigate('/patrons-circle', guestRedirectProps);
    } else {
      showContentBlockingPopup(POPUPS_MAP.UPGRADE_TO_PREMIUM_FOR_FEATURE);
    }
  }, [playlist?.required_subscription_plan, showContentBlockingPopup]);

  const toggleVideoPlayState = useCallback(shouldPlay => {
    setIsPlaying(shouldPlay);
  }, []);

  const handlers = useMemo(
    () => ({
      onReady,
      onStart,
      onProgress,
      onEnded,
      showContentBlockingPopup,
      handleBlockingPopupClose,
      setCurrentVideo: navigateToVideo,
      showGiftPopup,
      toggleVideoPlayState
    }),
    [
      onReady,
      onStart,
      onProgress,
      onEnded,
      showContentBlockingPopup,
      handleBlockingPopupClose,
      navigateToVideo,
      showGiftPopup,
      toggleVideoPlayState
    ]
  );

  if (!currentVideo || !playlist?.videos) {
    return null;
  }

  return (
    <MarketingHeroPlaylistLayout
      playlistUid={playlist.uid}
      currentVideo={currentVideo}
      currentVideoIndex={currentVideoIndex}
      numberOfVideos={playlist.videos.length}
      handlers={handlers}
      popup={popup}
      isPlaying={isPlaying}
      isPopupOpen={isPopupOpen}
      title={playlist.title}
      author={playlist.author}
      prismicId={playlist.prismicId}
      uid={playlist.uid}
      suggestedPlaylists={playlist.suggestedPlaylists}
      trendingPlaylists={playlist.trendingPlaylists}
      popularPlaylists={playlist.popularPlaylists}
      labels={labels}
      thumbnail={playlist.firstVideo.cover_image}
      isSingleVideo={playlist.videos.length === 1}
      heroCoverImage={playlist.heroCoverImage}
      isFreeContent={!!playlist.allowFullWatch}
    />
  );
};

MarketingHeroPlaylist.propTypes = {
  playlist: PropTypes.shape({
    title: PropTypes.string,
    uid: PropTypes.string,
    author: PropTypes.shape({}),
    materials: PropTypes.arrayOf(PropTypes.shape({})),
    prismicId: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string
      })
    ),
    required_subscription_plan: PropTypes.number,
    allowFullWatch: PropTypes.bool,
    suggestedPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}),
        url: PropTypes.string
      })
    ),
    trendingPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}),
        url: PropTypes.string
      })
    ),
    popularPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}),
        url: PropTypes.string
      })
    ),
    firstVideo: PropTypes.shape({
      cover_image: PropTypes.shape({
        localFile: PropTypes.shape({}),
        alt: PropTypes.string,
        url: PropTypes.string
      }).isRequired
    }).isRequired,
    heroCoverImage: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string
    })
  }).isRequired,
  videoId: PropTypes.number,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  giftToken: PropTypes.string,
  allowedSeconds: PropTypes.number
};

MarketingHeroPlaylist.defaultProps = {
  videoId: undefined,
  giftToken: undefined,
  allowedSeconds: 90 // default allowed seconds for the marketing site
};

export default MarketingHeroPlaylist;
